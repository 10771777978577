import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import HubspotForm from "components/hubspot"
import Container from "components/container"


const StyledStarterKitForm = styled.section`
  padding: 50px 0px;

  ${breakpoint.medium`
	  padding: 80px 0px;  
  `}

  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1%;

    ${breakpoint.medium`
		column-gap: 3%;
	  `}

  }

  .introduction {
    margin: 0 10px;
    
    p { margin: 15px 0 0;
    line-height: 1.5em; }
    
  }

  .contact__form {
    flex: 1 0 auto;
    max-width: 600px;
    margin-block: 25px;
    width: 100%;
    padding: 0 10px;
        
  }


  .contact__info {
    flex: 1 0 30%;
    min-width: 280px;
    width: 100%;
    margin-block: 15px;

    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }

    ${breakpoint.medium`
        width: 50%;
    `}
  }

  .bottomText {
    text-align: center;
  }


  .subscriptionLabel {
    font-size: 14px !important;
    line-height: 1.4em;
    color: ${colors.charcoal};
    margin: 20px 0 20px 5px;
  }


  .google__privacy {
    font-size: 12px;
    line-height: 1.4em;
    margin: 12px 20px 0px 8px;
    color: ${colors.graySelect};
  }

  a {
    color: ${colors.orange};

    &:hover {
      color: ${colors.purpleDark};
    }
  }



`

const StarterKitForm = () => {

  return (
    <StyledStarterKitForm>
      <Container>
        <div className="introduction">
          <p><b>Congratulations!</b> Your organization recently made the decision to incorporate the Visby Medical test in your clinical setting. We are incredibly excited to partner with you and want to ensure you have the best experience with our products.</p>
          <p>To help you get started, please fill out the form below to sign up for complimentary support material to be sent to your location. You will receive tracking information (once items ship) for this material and given access to schedule both Provider and End User training in this follow up correspondence. </p>
          <p>Our objective is to aid in navigating through the implementation process; resulting in efficient and easier workflows boosting clinician, staff and patient satisfaction. Thank you for being a Visby customer. </p>
        </div>
        <div className="contact__form" data-aos="fade-up">
          <HubspotForm
            formId='e77cf0fc-38a3-44f5-ba32-61d315c5aa98'
          />
        </div>

        <div className="contact__info" data-aos="fade-up">
          <p>
            <strong>Customer Support</strong>
          </p>
          <p>Hours: Mon-Fri 8:00 am - 4:00 pm PST</p>
          <p>Email: <a href="mailto:support@visby.com">support@visby.com</a></p>
          <p>Phone: <a href="tel:+1833-468-4729">1-833-GoVisby</a> (1-833-468-4729)</p>
        </div>
      </Container>
    </StyledStarterKitForm>
  )
}

export default StarterKitForm
